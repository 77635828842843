<template>
  <div class="container h-100 content">
    <div class="d-flex justify-content-center h-100">
      <div class="login block">
        <form @submit.prevent="register">
          <img
            alt="Data Energy"
            v-bind:src="getLogoBase64"
            style="width:80%; margin-bottom: 20px;"
          />

          <h2>{{ env }}Register</h2>

          <div v-if="isComplete">
            <b-alert variant="info" show>
              <strong>Activation Required</strong>
              <div>
                We have sent you an e-mail to activate your account. Once
                activated please proceed to
                <span @click="loadLogin">login</span>
              </div>
            </b-alert>

            <hr />

            <div>
              <span @click="loadLogin" class="password-link">Login</span>
            </div>
          </div>

          <div v-else>
            <div class="element">
              <input
                required
                v-model="form.email"
                type="email"
                placeholder="E-mail Address"
              />
            </div>

            <div class="element">
              <input
                required
                v-model="form.password"
                type="password"
                placeholder="Password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
              />
            </div>

            <div class="element">
              <input
                required
                v-model="form.passwordConfirm"
                type="password"
                placeholder="Confirm Password"
              />
            </div>

            <b-button type="submit" variant="success">Register</b-button>

            <hr />

            <div>
              <span @click="loadLogin" class="password-link"
                >Already have an account?</span
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { recaptcha } from "../../components/mixins/recaptcha";
import { logoData } from "@/components/mixins/logo";

export default {
  name: "Register",
  mixins: [recaptcha, logoData],
  data() {
    return {
      loading: false,
      isComplete: false,
      form: {
        email: "",
        password: "",
        passwordConfirm: ""
      }
    };
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    }
  },
  methods: {
    loadLogin() {
      this.$router.push("/login");
    },
    async register() {
      if (this.form.password !== this.form.passwordConfirm) {
        const title = "Password's do not match";
        const subtitle = "Please confirm the same password";
        const samePage = true;
        await this.$store.dispatch("pushError", { title, subtitle, samePage });
        return;
      }

      const title = "Setting up your account";
      const subtitle = "Please wait a moment";
      await this.$store.dispatch("loaderInitiate", { title, subtitle });

      const token = await this.recaptchaToken();

      const email = this.form.email;
      const password = this.form.password;
      const passwordConfirm = this.form.passwordConfirm;
      this.$store
        .dispatch("register", { email, password, passwordConfirm, token })
        .then(() => {
          this.isComplete = true;
        })
        .catch(err => {
          this.$swal({
            type: "error",
            title: "Oops...",
            text: err
          });
        })
        .finally(() => {
          this.$store.dispatch("loaderCancel");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.password-link {
  cursor: pointer;
  font-size: 0.8rem;
}

h2 {
  text-align: center !important;
  font-size: 1.4rem !important;
}

h3 {
  font-size: 1.1rem;
}
</style>
